<template>
<div class="app-container">
  <el-col :sm="24" :md="16" >
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>Cumpleaños</span>
      </div>
      <div class="text item">
      <ValidationObserver ref="form" v-slot="{ invalid }">
          <el-form label-position="top" label-width="100px" :model="form">
              <el-image :src="$urlGlobalApi + form.header">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
              <el-form-item label="Imagen de Cumpleaños">
            <el-upload
                drag
                name="image"
                action="#"
                :http-request="postImage"
                :on-success="handleAvatarSuccess"
                multiple>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">Suelta tu foto aquí o <em>haz clic para cargar</em></div>
                <div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 500kb</div>
              </el-upload>
            </el-form-item>
              <el-form-item label="Título">
                <ValidationProvider name="Titulo" rules="required|max:255" v-slot="{ errors }">
                  <el-input size="mini" v-model="form.title"></el-input>
                  <span>{{ errors[0] }}</span><br>
                  <span>Para aludir al usuario escriba "[+user+]" </span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Descripción">
                <ValidationProvider name="Description" rules="required|max:500" v-slot="{ errors }">
                  <el-input size="mini" v-model="form.description"></el-input>
                  <span>{{ errors[0] }}</span><br>
                  <span>Para aludir al usuario escriba "[+user+]" </span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item>
              <el-button
                @click.stop.prevent="onUpdate"
                type="primary"
                icon="el-icon-upload" :disabled="invalid">
                Actualizar</el-button>
              </el-form-item>
          </el-form>
        </ValidationObserver>
        </div>
    </el-card>
  </el-col>
</div>
</template>

<script>
import { getBirthday, updateBirthday } from '@/api/birthday'
import { uploadImageFile } from '@/api/image'

export default {
  data () {
    return {
      form: {
        title: '',
        description: '',
        header: ''
      },
      loading: false
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    async postImage (param) {
      this.loading = true
      const formData = new FormData()
      formData.append('image', param.file)
      formData.append('width', '500')
      formData.append('height', '400')
      formData.append('title', 'Luis')
      formData.append('folder', 'img/avatars')
      formData.append('alt', 'Avigrupo-image')
      uploadImageFile(formData).then((response) => {
        console.log(response)
        param.onSuccess(response.data.path)
      })
        .catch(response => {
          console.log(response)
          param.onError()
        })
        .finally(() => (this.loading = false))
    },
    async fetchData () {
      this.loading = true
      await getBirthday()
        .then(response => {
          // console.log(response.data)
          this.form = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async onUpdate () {
      console.log(this.form)
      await updateBirthday(this.form._id, this.form)
        .then((response) => {
          this.$message({
            showClose: true,
            message: 'Datos actualizados!',
            type: 'success'
          })
          // this.$router.push({ name: 'admin' })
        })
        .catch(this.responseCatch)
    },
    handleAvatarSuccess (res, file) {
      this.form.header = res
      // console.log(this.form.header)
    }
  }
}
</script>
